import React, { useEffect, useState } from 'react';
import api from '../../api/api';

const PageUsuario = () => {
  const [erros, setErros] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("Vocabulario/Pesquisar?tipo=0&periodo=4&praticando=false&corrigirErros=true").then(resp => {
      console.log(resp.data);
      setErros(resp.data);
      setLoading(false);
    });
  }, [loading]);

  return (
    <>
      {erros.length > 0 ? (
        erros.map((e, index) => (
          <div key={index} className='text-danger bg-dark'>
            <p>emIngles: {e.emIngles}</p>
            <p>explicacao: {e.explicacao}</p>
            <p>id: {e.id}</p>
            <p>inativo: {e.inativo.toString()}</p>
            <p>tipoVocabulario: {e.tipoVocabulario}</p>
            <p>traducao: {e.traducao}</p>
            <p>userId: {e.userId}</p>
          </div>
        ))
      ) : (
        <div>Nenhum erro encontrado.</div>
      )}
    </>
  );
};

export default PageUsuario;
